import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import CoverLayout from "../components/CoverLayout"
import { HeadComponent } from "../components/HeadComponent"

import { listePartenaires } from './partenaires.module.scss'

const ReferencesPage = ({ data }) => {

  const coverImage = {
    image: data.cover,
    alt: "© - Milomon - https://www.instagram.com/milomon/",
  }

  return (
    <Layout>
      <HeadComponent title="Urbanisme & Cartographie" />
      
      <CoverLayout coverImage={coverImage}>
          <ul className={listePartenaires}>
            <li>
            <GatsbyImage image={data.avant_projet.childImageSharp.gatsbyImageData} />
              <span >
                <a
                  href="https://avant-projet.eu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  
                  Avant-Projet
                </a>
              </span>{" "}
              Bureau d’études d’urbanisme, d’ingénierie foncière et de
              programmation urbaine réunissant des compétences en aménagement
              urbain, architecture, sociologie urbaine, programmation urbaine et
              montage d’opérations.
            </li>
            <li>
            <GatsbyImage image={data.eacm.childImageSharp.gatsbyImageData} />
              <span >
                <a
                  href="https://www.eacm.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EACM
                </a>
              </span>{" "}
              Bureau d'études et de conseil dans les domaines de
              l’environnement, du foncier et des matériaux, ses compétences et
              les missions qui lui sont confiées sont variées : gestion des
              sites pollués, valorisation et traitement de matériaux, dossiers
              réglementaires (ICPE, loi sur l’eau), audits environnementaux,
              valorisation du foncier…
            </li>
          </ul>
      </CoverLayout>
    </Layout>
  );}

  
export const pageQuery = graphql`{
  cover: file(relativePath: {eq: "milomon/B29rMoCoLz5.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  eacm: file(relativePath: {eq: "logo_eacm.png"}) {
    childImageSharp {
      gatsbyImageData(height: 80, placeholder: TRACED_SVG, layout: FIXED)
    }
  }
  avant_projet: file(relativePath: {eq: "logo_avp.png"}) {
    childImageSharp {
      gatsbyImageData(height: 80, layout: FIXED)
    }
  }
}
`



export default ReferencesPage
